import React from 'react';
import { Box } from '@mui/material';
import Flex from './ui/Flex';
import Center from './ui/Center';
import { colors } from '../theme';
import { Body1, Body2 } from './ui/Typography';
import { ls } from '../i18n/translations';
import GaugeChart from './charts/GaugeChart';
import InOutItem from './InOutItem';
import ProgressPercChart from './charts/ProgressPercChart';
import { useMQ } from '../hook/useMQ';
import { useInterval, useSetState, useWindowSize } from 'react-use';
import {
  calculateGaugeArcsLength,
  capitalize,
  dayjs,
  getAlarmMetadata,
  getChartParamsValues,
  isModuleInCommErr,
  isNotNullAndNotUndefined,
  minMaxCap,
  parseUnitOfMeasure,
  randomFloat,
  randomWait,
  skipAlarmKey,
} from '../helpers/utils';
import {
  MachineType,
  MachineVersion,
  Maybe,
  Module,
  UnitOfMeasure,
} from '../graphql/codegen/graphql-react';
import {
  AlarmsType,
  InputsType,
  MeasurementsType,
  ModuleSettingsType,
  OutputsType,
  ParametersType,
} from '../graphql/dataTypes';
import { CustomSvgIcon, assets } from '../helpers/assets';
import { useForceUpdate } from '../hook/useForceUpdate';
import { useAuth } from '../hook/useAuth';
import { useDarkMode } from '../hook/useDarkMode';

interface InterfaceDetailProps {
  module: Maybe<Module>;
}

const InterfaceDetail: React.FC<InterfaceDetailProps> = ({ module }) => {
  const { isMobile, isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();
  const { width: windowWidth } = useWindowSize();
  const auth = useAuth();
  useForceUpdate(6000);

  if (!module) {
    return null;
  }

  const moduleSettings =
    (module.ModuleSettings?.settings as ModuleSettingsType)?.filter((s) => s?.enabled) || [];
  const moduleMeasurements = {} as MeasurementsType;
  // do not handle the disabled measurements
  for (let i = 0; i < 7; i++) {
    const mIdx = Object.keys(moduleMeasurements || {}).length;
    if (((module.ModuleSettings?.settings as ModuleSettingsType) || [])[i]?.enabled) {
      // @ts-ignore
      moduleMeasurements[`measurement${mIdx + 1}`] =
        module.Measurement?.measurements[`measurement${i + 1}`];
    }
  }
  const nOfSensors = moduleSettings?.length || 0;
  const moduleInputs = module.Input?.inputs as InputsType;
  const moduleOutputs = module.Output?.outputs as OutputsType;
  const moduleAlarms = module.Alarm?.alarms as AlarmsType;
  const chartParamsValues = getChartParamsValues(module);

  const progressChartWidth = isMobile ? windowWidth * 0.8 : minMaxCap(250, 500, windowWidth * 0.5); //500;
  const gaugeChartWidth = isMobile
    ? minMaxCap(150, 350, windowWidth * 0.8)
    : minMaxCap(150, 350, windowWidth * 0.4);
  const gaugeChartHeight = gaugeChartWidth * 0.51;

  const fontChartSize = isMobileOrTablet ? '0.8rem' : '0.9rem';

  const isModuleCommErr = isModuleInCommErr(module);

  const GaugeChartStyled: React.FC<{ idx: number }> = ({ idx }) => {
    const decimal = moduleSettings?.[idx]?.decimal ?? 2;
    const paramsValues = chartParamsValues[idx] ?? {};
    let uom = moduleSettings?.[idx]?.unitOfMeasure;
    if (
      (module.type === MachineType.Ew2Pool || module.type === MachineType.Nova2Pool) &&
      idx === 2 // only for measurement n° 3
    ) {
      const parameters = (module.Parameter?.parameters || {}) as ParametersType;
      if (parameters?.udmTemp === 1) {
        uom = parseUnitOfMeasure(UnitOfMeasure.F) as UnitOfMeasure;
      } else {
        uom = parseUnitOfMeasure(UnitOfMeasure.C) as UnitOfMeasure;
      }
    }
    const min = paramsValues.min ?? moduleSettings?.[idx]?.scaleStart;
    const max = paramsValues.max ?? moduleSettings?.[idx]?.scaleEnd;
    // @ts-ignore
    const currentValue = moduleMeasurements?.[`measurement${idx + 1}`] as number | undefined;
    let isUR = false;
    let isOR = false;
    if (currentValue !== undefined) {
      if (currentValue > max) {
        isOR = true;
      }
      if (currentValue < min) {
        isUR = true;
      }
    }
    return (
      <Box>
        <Center flex={1} mt={4} flexDirection='column'>
          <Box
            borderRadius={6}
            border='1px solid'
            p={1}
            width={gaugeChartWidth * 0.7}
            borderColor={colors.lightGrey}
          >
            <Center>
              <Body2 color={isDarkMode ? colors.lightGrey : colors.stroke} weight={600}>
                {`${moduleSettings?.[idx]?.name || '-'}:`}
              </Body2>
              <Body2 ml={0.5} color={colors.orange}>
                {isUR
                  ? 'UR'
                  : isOR
                  ? 'OR'
                  : `${
                      // @ts-ignore
                      moduleMeasurements?.[`measurement${idx + 1}`]?.toFixed(decimal) ?? '-'
                    }${uom}`}
              </Body2>
            </Center>
          </Box>
          <Box mt={2} mb={1}>
            <GaugeChart
              key={1}
              min={min}
              max={max}
              unitOfMeasure={uom}
              setpoint={paramsValues.setpoint ?? moduleSettings?.[idx]?.setpoint}
              hideSetpoint={paramsValues.hideSetpoint}
              decimal={decimal}
              currentValue={currentValue}
              colors={
                moduleSettings?.[idx]?.widgetChart === 0
                  ? ['#ea3a4a', '#77c745', '#ea3a4a']
                  : moduleSettings?.[idx]?.widgetChart === 3
                  ? ['#ea3a4a', '#ffba23', '#77c745', '#ffba23', '#ea3a4a']
                  : ['#fdebed', '#ea3a4a']
              }
              arcsLength={
                moduleSettings?.[idx]?.widgetChart === 0 || moduleSettings?.[idx]?.widgetChart === 3
                  ? calculateGaugeArcsLength({
                      min: paramsValues.min ?? moduleSettings?.[idx]?.scaleStart,
                      max: paramsValues.max ?? moduleSettings?.[idx]?.scaleEnd,
                      minAcc:
                        paramsValues.minAcceptableLimit ??
                        moduleSettings?.[idx]?.minAcceptableLimit,
                      maxAcc:
                        paramsValues.maxAcceptableLimit ??
                        moduleSettings?.[idx]?.maxAcceptableLimit,
                      minWarn:
                        paramsValues.minWarningLimit ?? moduleSettings?.[idx]?.minWarningLimit,
                      maxWarn:
                        paramsValues.maxWarningLimit ?? moduleSettings?.[idx]?.maxWarningLimit,
                      withWarn: moduleSettings?.[idx]?.widgetChart === 3,
                    })
                  : undefined
              }
              w={gaugeChartWidth}
              h={gaugeChartHeight}
            />
          </Box>
          <Box>
            <Center>
              <Body2 color={colors.grey} size={fontChartSize}>
                {`${ls.minAcceptableValue.toUpperCase()}:`}
              </Body2>
              <Body2
                ml={0.5}
                color={isDarkMode ? colors.lightBlue : colors.blue}
                size={fontChartSize}
              >
                {`${
                  paramsValues.minAcceptableLimit?.toFixed(decimal) ??
                  moduleSettings?.[idx]?.minAcceptableLimit?.toFixed(decimal)
                }${uom}`}
              </Body2>
            </Center>
            <Center>
              <Body2 color={colors.grey} size={fontChartSize}>
                {`${ls.maxAcceptableValue.toUpperCase()}:`}
              </Body2>
              <Body2
                ml={0.5}
                color={isDarkMode ? colors.lightBlue : colors.blue}
                size={fontChartSize}
              >
                {`${
                  paramsValues.maxAcceptableLimit?.toFixed(decimal) ??
                  moduleSettings?.[idx]?.maxAcceptableLimit?.toFixed(decimal)
                }${uom}`}
              </Body2>
            </Center>
          </Box>
        </Center>
      </Box>
    );
  };

  const ProgressChartStyled: React.FC<{ idx: number }> = ({ idx }) => {
    const decimal = moduleSettings?.[idx]?.decimal ?? 2;
    const paramsValues = chartParamsValues[idx] ?? {};
    let uom = moduleSettings?.[idx]?.unitOfMeasure;
    if (
      (module.type === MachineType.Ew2Pool || module.type === MachineType.Nova2Pool) &&
      idx === 2 // only for measurement n° 3
    ) {
      const parameters = (module.Parameter?.parameters || {}) as ParametersType;
      if (parameters?.udmTemp === 1) {
        uom = parseUnitOfMeasure(UnitOfMeasure.F) as UnitOfMeasure;
      } else {
        uom = parseUnitOfMeasure(UnitOfMeasure.C) as UnitOfMeasure;
      }
    }
    const min = paramsValues.min ?? moduleSettings?.[idx]?.scaleStart ?? 0;
    const max = paramsValues.max ?? moduleSettings?.[idx]?.scaleEnd ?? 100;
    // @ts-ignore
    const currentValue = moduleMeasurements?.[`measurement${idx + 1}`] as number | undefined;
    let isUR = false;
    let isOR = false;
    if (currentValue !== undefined) {
      if (currentValue > max) {
        isOR = true;
      }
      if (currentValue < min) {
        isUR = true;
      }
    }
    return (
      <Box>
        <Flex
          mt={5}
          ml={isMobileOrTablet ? 0 : 4}
          flex={1}
          // mr={8}
          flexDirection='column'
          alignItems='flex-end'
        >
          {/* fix ui */}
          <Flex justifyContent='flex-start'>
            <Flex
              justifyContent='flex-start'
              width={progressChartWidth * (isMobileOrTablet ? 0.58 : 0.48)}
            >
              <Body2 color={isDarkMode ? colors.lightGrey : colors.stroke} weight={600}>{`${
                moduleSettings?.[idx]?.name || '-'
              }:`}</Body2>
              <Body2 color={colors.orange} ml={0.5}>
                {isUR
                  ? 'UR'
                  : isOR
                  ? 'OR'
                  : `${
                      // @ts-ignore
                      moduleMeasurements?.[`measurement${idx + 1}`]?.toFixed(decimal) ?? '-'
                    }${uom}`}
              </Body2>
            </Flex>
            <Box width={progressChartWidth * 0.48} />
          </Flex>
          <Box my={1}>
            <ProgressPercChart
              min={min}
              max={max}
              minAcceptableValue={
                paramsValues.minAcceptableLimit ?? moduleSettings?.[idx]?.minAcceptableLimit ?? 1
              }
              maxAcceptableValue={
                paramsValues.maxAcceptableLimit ?? moduleSettings?.[idx]?.maxAcceptableLimit ?? 50
              }
              currentValue={currentValue}
              width={progressChartWidth}
            />
          </Box>
          <Flex
            width={progressChartWidth * (isMobileOrTablet ? 1.05 : 0.955)}
            alignItems='flex-start'
          >
            {!paramsValues.hideSetpoint ? (
              <Center>
                <Body2 color={colors.grey} size={fontChartSize}>
                  {ls.setpoint.toUpperCase()}
                </Body2>
                <Body2
                  color={isDarkMode ? colors.lightBlue : colors.blue}
                  ml={0.5}
                  size={fontChartSize}
                >
                  {`${
                    isNotNullAndNotUndefined(paramsValues.setpoint)
                      ? paramsValues.setpoint.toFixed(decimal)
                      : moduleSettings?.[idx]?.setpoint?.toFixed(decimal)
                  } ${uom}`}
                </Body2>
              </Center>
            ) : (
              <Box />
            )}
            <Flex flexDirection='column' justifyContent='center' alignItems='flex-end' mr={1.5}>
              <Center>
                <Body2 color={colors.grey} size={fontChartSize}>
                  {`${ls.minAcceptableValue.toUpperCase()}:`}
                </Body2>
                <Body2
                  color={isDarkMode ? colors.lightBlue : colors.blue}
                  ml={0.5}
                  size={fontChartSize}
                >
                  {`${
                    paramsValues.minAcceptableLimit?.toFixed(decimal) ??
                    moduleSettings?.[idx]?.minAcceptableLimit?.toFixed(decimal)
                  } ${uom}`}
                </Body2>
              </Center>
              <Center>
                <Body2 color={colors.grey} size={fontChartSize}>
                  {`${ls.maxAcceptableValue.toUpperCase()}:`}
                </Body2>
                <Body2
                  color={isDarkMode ? colors.lightBlue : colors.blue}
                  ml={0.5}
                  size={fontChartSize}
                >
                  {`${
                    paramsValues.maxAcceptableLimit?.toFixed(decimal) ??
                    moduleSettings?.[idx]?.maxAcceptableLimit?.toFixed(decimal)
                  } ${uom}`}
                </Body2>
              </Center>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <Box height='100%' width='100%' position='relative'>
      {isModuleCommErr ? (
        <Box position='absolute' top={0} left={0} right={0} style={{ opacity: 1, zIndex: 999 }}>
          {module.online ? (
            <Center>
              <Box
                width={
                  isMobile
                    ? windowWidth * 0.75
                    : isMobileOrTablet
                    ? windowWidth * 0.5
                    : windowWidth * 0.25
                }
                mt={10}
                p={2}
                style={{
                  backgroundColor: isDarkMode ? colors.darkBackground : 'white',
                  borderRadius: 8,
                  border: isDarkMode ? '1px solid #5a5c6f' : '1px solid black',
                }}
              >
                <Center>
                  <Box>
                    <CustomSvgIcon src={assets.commErr} style={{ width: 75, height: 16 }} />
                  </Box>
                </Center>
                <Center>
                  <Box>
                    <Body1
                      size='1.1rem'
                      color={isDarkMode ? colors.lightGrey : colors.black}
                      weight={400}
                      style={{ textAlign: 'center' }}
                    >
                      {capitalize(ls.moduleComunicationTimeoutErrDesc)}
                    </Body1>
                  </Box>
                </Center>
              </Box>
            </Center>
          ) : null}
        </Box>
      ) : null}
      <Flex flexDirection='column' style={{ opacity: isModuleCommErr ? 0.4 : 1 }}>
        {!moduleMeasurements || !moduleSettings || moduleSettings.length === 0 ? (
          <>
            <Center my={5}>
              <Body2>{capitalize(ls.noMeasurementsFoundOrEnabled)}</Body2>
            </Center>
            <Box className='separator' width='100%' />
          </>
        ) : (
          <>
            <Center
              width={isMobileOrTablet ? '100%' : 500}
              mb={nOfSensors > 2 ? 0 : 5}
              ml={
                nOfSensors >= 2 &&
                moduleSettings?.[0]?.widgetChart === 2 &&
                moduleSettings?.[1]?.widgetChart === 2
                  ? -5.5
                  : 0
              }
            >
              <Flex
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                {Array(minMaxCap(1, 2, nOfSensors))
                  .fill(0)
                  .map((el, i) => {
                    return (
                      <Box key={i} width={'100%'}>
                        {moduleSettings?.[i]?.widgetChart === 0 ||
                        moduleSettings?.[i]?.widgetChart === 1 ||
                        moduleSettings?.[i]?.widgetChart === 3 ? (
                          <GaugeChartStyled idx={i} />
                        ) : (
                          <ProgressChartStyled idx={i} />
                        )}
                      </Box>
                    );
                  })}
              </Flex>
            </Center>
            {/* TODO make a ChartView component */}
            {nOfSensors > 2 ? (
              <Center
                width={isMobileOrTablet ? '100%' : 500}
                mb={5}
                ml={
                  isMobileOrTablet
                    ? 0
                    : nOfSensors >= 4 &&
                      moduleSettings?.[2]?.widgetChart === 2 &&
                      moduleSettings?.[3]?.widgetChart === 2
                    ? -5.5
                    : 0
                }
              >
                <Flex
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  {Array(minMaxCap(1, 2, nOfSensors - 2))
                    .fill(0)
                    .map((el, i) => {
                      const idx = i + 2;
                      return (
                        <Box key={idx} width={'100%'}>
                          {moduleSettings?.[idx]?.widgetChart === 0 ||
                          moduleSettings?.[idx]?.widgetChart === 1 ||
                          moduleSettings?.[idx]?.widgetChart === 3 ? (
                            <GaugeChartStyled idx={idx} />
                          ) : (
                            <ProgressChartStyled idx={idx} />
                          )}
                        </Box>
                      );
                    })}
                </Flex>
              </Center>
            ) : null}
            {nOfSensors > 4 ? (
              <Center
                width={isMobileOrTablet ? '100%' : 500}
                mb={5}
                ml={
                  isMobileOrTablet
                    ? 0
                    : nOfSensors >= 6 &&
                      moduleSettings?.[4]?.widgetChart === 2 &&
                      moduleSettings?.[5]?.widgetChart === 2
                    ? -5.5
                    : 0
                }
              >
                <Flex
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  {Array(minMaxCap(1, 2, nOfSensors - 4))
                    .fill(0)
                    .map((el, i) => {
                      const idx = i + 4;
                      return (
                        <Box key={idx} width={'100%'}>
                          {moduleSettings?.[idx]?.widgetChart === 0 ||
                          moduleSettings?.[idx]?.widgetChart === 1 ||
                          moduleSettings?.[idx]?.widgetChart === 3 ? (
                            <GaugeChartStyled idx={idx} />
                          ) : (
                            <ProgressChartStyled idx={idx} />
                          )}
                        </Box>
                      );
                    })}
                </Flex>
              </Center>
            ) : null}
            {nOfSensors > 6 ? (
              <Center
                width={isMobileOrTablet ? '100%' : 500}
                mb={5}
                ml={
                  isMobileOrTablet
                    ? 0
                    : nOfSensors >= 8 &&
                      moduleSettings?.[6]?.widgetChart === 2 &&
                      moduleSettings?.[7]?.widgetChart === 2
                    ? -5.5
                    : 0
                }
              >
                <Flex
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  {Array(minMaxCap(1, 2, nOfSensors - 6))
                    .fill(0)
                    .map((el, i) => {
                      const idx = i + 6;
                      return (
                        <Box key={idx} width={'100%'}>
                          {moduleSettings?.[idx]?.widgetChart === 0 ||
                          moduleSettings?.[idx]?.widgetChart === 1 ||
                          moduleSettings?.[idx]?.widgetChart === 3 ? (
                            <GaugeChartStyled idx={idx} />
                          ) : (
                            <ProgressChartStyled idx={idx} />
                          )}
                        </Box>
                      );
                    })}
                </Flex>
              </Center>
            ) : null}
            <Box className='separator' width='100%' />
          </>
        )}
        <Box width='100%'>
          <Box mx={isMobileOrTablet ? 1 : 0}>
            <Flex
              flex={1}
              width='100%'
              flexDirection={isMobileOrTablet ? 'column' : 'row'}
              alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
            >
              {!auth.isViewer ? (
                <Flex flexDirection='column' flex={1} width='100%' alignSelf='flex-start'>
                  <Box flex={1} ml={isMobileOrTablet ? 0 : 4} width='100%'>
                    <Box mt={4} mb={2}>
                      <Body1 ml={2} weight={700}>
                        {ls.inputs}
                      </Body1>
                    </Box>
                  </Box>
                  {Object.keys(moduleInputs || {}).map((k, i) => {
                    const moduleInputValue: undefined | null | boolean | number =
                      // @ts-ignore
                      moduleInputs?.[k];
                    if (typeof moduleInputValue !== 'boolean') {
                      return null;
                    }
                    if (
                      (module!.type === MachineType.Cycleau &&
                        (k === 'lvl3' || k === 'extcons' || k === 'lvl')) ||
                      (module!.type === MachineType.Mc14Evo && (k === 'lvl3' || k === 'imp')) ||
                      (module!.type === MachineType.Ef315 &&
                        k !== 'lvl1' &&
                        k !== 'lvl2' &&
                        k !== 'flow' &&
                        k !== 'extcons')
                    ) {
                      return null;
                    }
                    return (
                      <Box
                        key={`input_${i}`}
                        flex={1}
                        ml={isMobileOrTablet ? 0 : 4}
                        width='100%'
                        mb={1}
                      >
                        <InOutItem
                          name={
                            // @ts-ignore
                            capitalize(ls[`input_${k}`] || '-') + ':'
                          }
                          isActive={!!moduleInputValue}
                          bgColor={moduleInputValue ? colors.green : colors.grey}
                        />
                      </Box>
                    );
                  })}
                </Flex>
              ) : null}
              <Box
                flex={1}
                width='100%'
                ml={auth.isViewer && !isMobileOrTablet ? 2 : isMobileOrTablet ? 0 : 6}
                mr={auth.isViewer && isMobileOrTablet ? 0 : isMobileOrTablet ? 0 : 2}
                alignSelf='flex-start'
              >
                <Box mt={4} mb={2} width='100%'>
                  <Body1 ml={2} weight={700}>
                    {ls.outputs}
                  </Body1>
                </Box>
                {Object.keys(moduleOutputs || {}).map((o, i) => {
                  const moduleOutputValue: undefined | null | boolean | number =
                    // @ts-ignore
                    moduleOutputs?.[o];
                  if (typeof moduleOutputValue !== 'boolean') {
                    return null;
                  }
                  const parameters = (module.Parameter?.parameters || {}) as ParametersType;
                  if (
                    (module!.type === MachineType.Cycleau && o === 'magnetStatus') ||
                    (module!.type === MachineType.Mc14Evo &&
                      (o === 'ev' ||
                        o === 'perist1' ||
                        o === 'perist2' ||
                        o === 'perist3' ||
                        o === 'mixer')) ||
                    (module!.type === MachineType.NovaNsp &&
                      (module!.modelVersion === MachineVersion.Nsp161CMin ||
                        module!.modelVersion === MachineVersion.Nsp161Ma) &&
                      (o === 'maxDoseTime' || o === 'sampleWater' || o === 'maxAccPulses')) ||
                    (module!.type === MachineType.NovaNsp &&
                      module!.modelVersion !== MachineVersion.Nsp161CMin &&
                      module!.modelVersion !== MachineVersion.Nsp161Ma &&
                      (o === 'autoadesco' || o === 'maxAccPulses')) ||
                    (module!.type === MachineType.Ew2Pool &&
                      (o === 'phMagnet' || o === 'clMagnet')) ||
                    (module!.type === MachineType.Nova2Pool &&
                      o !== 'phMagnet' &&
                      o !== 'clMagnet') ||
                    o === 'phMagnetValue' ||
                    o === 'clMagnetValue' ||
                    o === 'pfmM1Value' ||
                    o === 'pfmM2Value'
                  ) {
                    return null;
                  }
                  let isCustomPfmStatus = false;
                  const customPfmValue =
                    // @ts-ignore
                    Number(moduleOutputs?.[`${o}Value`]) || 0;
                  if (module!.type === MachineType.Ew2Pool && (o === 'pfmM1' || o === 'pfmM2')) {
                    isCustomPfmStatus = true;
                  }
                  let isCustomMagnetStatus =
                    module!.type === MachineType.Nova2Pool &&
                    (o === 'phMagnet' || o === 'clMagnet');
                  const customMagnetValue =
                    // @ts-ignore
                    Number(moduleOutputs?.[`${o}Value`]) || 0;
                  return (
                    <Box key={`output_${i}`} mt={1}>
                      <InOutItem
                        name={
                          // @ts-ignore
                          capitalize(ls[`output_${o}`] || '-') + ':'
                        }
                        isActive={
                          isCustomPfmStatus
                            ? customPfmValue > 0
                            : isCustomMagnetStatus
                            ? customMagnetValue > 0
                            : moduleOutputValue
                        }
                        customNotActiveText={
                          isCustomPfmStatus ? '0 cpm' : isCustomMagnetStatus ? '0 cpm' : undefined
                        }
                        customActiveText={
                          isCustomPfmStatus
                            ? `${customPfmValue} cpm`
                            : isCustomMagnetStatus
                            ? `${customMagnetValue} cpm`
                            : undefined
                        }
                        // onSwitchValueChange={(v) => {}}
                        bgColor={
                          (
                            isCustomPfmStatus
                              ? customPfmValue > 0
                              : isCustomMagnetStatus
                              ? customMagnetValue > 0
                              : moduleOutputValue
                          )
                            ? o === 'alarm' || o === 'relayAlarm'
                              ? colors.red
                              : colors.green
                            : colors.grey
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
            </Flex>
          </Box>
          <Box mx={isMobileOrTablet ? 1 : 2}>
            <Box flex={1} width='100%' alignSelf='flex-start'>
              <Box mt={4} mb={2} width='100%'>
                <Body1 ml={2} weight={700}>
                  {ls.statuses}
                </Body1>
              </Box>
              {Object.keys(moduleAlarms || {})
                .sort((a, b) => {
                  const aAmd = getAlarmMetadata(a, module?.type!, module?.modelVersion!);
                  const bAmd = getAlarmMetadata(b, module?.type!, module?.modelVersion!);
                  const aAmdOrderNum = aAmd?.orderNum ?? 99;
                  const bAmdOrderNum = bAmd?.orderNum ?? 99;
                  if (aAmdOrderNum === bAmdOrderNum) {
                    return 0;
                  }
                  if (aAmdOrderNum < bAmdOrderNum) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((a, i) => {
                  const moduleAlarmValue: undefined | null | boolean | number =
                    // @ts-ignore
                    moduleAlarms?.[a];
                  if (typeof moduleAlarmValue !== 'boolean') {
                    return null;
                  }
                  if (skipAlarmKey(a, module)) {
                    return null;
                  }
                  const amd = getAlarmMetadata(a, module?.type!, module?.modelVersion!);
                  if (!amd) {
                    return null;
                  }
                  let translatedName = amd.translatedName;
                  if (translatedName.toLowerCase() === ls.alarm_new_stop.toLowerCase()) {
                    translatedName = ls.toolStatus;
                  }
                  // fix STEIEL-110
                  if (
                    (module?.type === MachineType.Mc14Evo ||
                      module?.type === MachineType.Mco14Evo) &&
                    translatedName.toLowerCase() === ls.off.toLowerCase()
                  ) {
                    translatedName = ls.toolStatus;
                  }
                  // STEIEL-132
                  let isCustomToolStatus = false;
                  let customToolStatusValue = 0;
                  if (
                    (module?.type === MachineType.Ew2Pool ||
                      module?.type === MachineType.Nova2Pool) &&
                    translatedName.toLowerCase() === ls.toolStatus.toLowerCase()
                  ) {
                    isCustomToolStatus = true;
                    customToolStatusValue =
                      Number(
                        // @ts-ignore
                        moduleAlarms?.[`${a}Value`],
                      ) || 256;
                  }
                  return (
                    <Box key={`alarm_${i}`} mt={1}>
                      <InOutItem
                        name={capitalize(translatedName || '-') + ':'}
                        isActive={
                          isCustomToolStatus
                            ? customToolStatusValue === 257 ||
                              customToolStatusValue === 14850 ||
                              customToolStatusValue === 15362
                            : !!moduleAlarmValue
                        }
                        // onSwitchValueChange={(v) => {}}
                        bgColor={
                          (
                            isCustomToolStatus
                              ? customToolStatusValue === 257 ||
                                customToolStatusValue === 14850 ||
                                customToolStatusValue === 15362
                              : !!moduleAlarmValue
                          )
                            ? isCustomToolStatus
                              ? customToolStatusValue === 14850 || customToolStatusValue === 15362
                                ? colors.red
                                : amd.activeColor
                              : amd.activeColor
                            : amd.disabledColor
                        }
                        customNotActiveText={amd.disabledTranslatedLabel}
                        customActiveText={
                          isCustomToolStatus
                            ? customToolStatusValue === 14850 || customToolStatusValue === 15362
                              ? ls.alarm
                              : amd.activeTranslatedLabel
                            : amd.activeTranslatedLabel
                        }
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Flex>
      {/* ui fix */}
      {nOfSensors > 2 ? <Box height={32} /> : null}
    </Box>
  );
};

export default InterfaceDetail;
